var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading && _vm.isFirstLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c("v-data-table", {
            attrs: {
              items: _vm.data.data,
              lazy: true,
              loading: _vm.api.isLoading,
              options: _vm.options,
              "items-per-page:sync": _vm.options.itemsPerPage,
              headers: _vm.dataHeader,
              "server-items-length": _vm.data.total,
              "sort-desc:sync": _vm.options.sortDesc,
              "sort-by": _vm.options.sortBy,
              "footer-props": {
                itemsPerPageOptions: [10, 20, 50],
                showCurrentPage: true,
              },
              dense: "",
            },
            on: {
              "update:options": [
                function ($event) {
                  _vm.options = $event
                },
                _vm.onPaginationUpdate,
              ],
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.options, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.options, "sortBy", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "tr",
                      {
                        class: item.read_at != null ? "" : "is-acknowledge",
                        on: {
                          click: function ($event) {
                            _vm.readNotification(
                              _vm.data.data[_vm.data.data.indexOf(item)]
                            )
                          },
                        },
                      },
                      [
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                (_vm.options.page - 1) *
                                  _vm.options.itemsPerPage +
                                  (_vm.data.data.indexOf(item) + 1)
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(item.created_at.split("T")[0]) +
                                " " +
                                _vm._s(
                                  _vm
                                    .convertTimeZone(item.created_at)
                                    .split(" ")[3]
                                ) +
                                " " +
                                _vm._s(
                                  _vm
                                    .convertTimeZone(item.created_at)
                                    .split(" ")[4]
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "text-decoration-none",
                              on: {
                                click: function ($event) {
                                  _vm.readNotification(
                                    _vm.data.data[_vm.data.data.indexOf(item)]
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          ),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.company_name) + " "),
                        ]),
                        _c("td", [
                          _vm._v(
                            " " + _vm._s(item.fname + " " + item.lname) + " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "text-h4 pt-4",
                        attrs: { flat: "", height: "auto" },
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "mx-4" },
                          [
                            _c("v-row", { attrs: { "no-gutters": "" } }, [
                              _vm._v(" Notification "),
                            ]),
                            _c(
                              "v-row",
                              [
                                _c("v-text-field", {
                                  staticClass: "mt-4",
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    clearable: "",
                                    label: "Search",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.fetch()
                                    },
                                  },
                                  model: {
                                    value: _vm.search,
                                    callback: function ($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }